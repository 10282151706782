@font-face {
    font-family: "Merriweather";
    src: url("../../fonts/Merriweather-Bold.woff2");
    src: url('../../fonts/Merriweather-Bold.woff2') format('woff2'),
         url("../../fonts/Merriweather-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato/Lato-Regular.woff2");
    src: url("../../fonts/Lato/Lato-Regular.woff2") format("woff2")
         url("../../fonts/Lato/Lato-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato/Lato-Semibold.woff2");
    src: url("../../fonts/Lato/Lato-Semibold.woff2") format("woff2"),
         url("../../fonts/Lato/Lato-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato/Lato-Bold.woff2");
    src: url("../../fonts/Lato/Lato-Bold.woff2") format("woff2"),
         url("../../fonts/Lato/Lato-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato/Lato-Heavy.woff2");
    src: url("../../fonts/Lato/Lato-Heavy.woff2") format("woff2"),
         url("../../fonts/Lato/Lato-Heavy.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

img {
    height: auto;
}

.screen-reader-only {
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.no-scroll {
    overflow: hidden;
}
